/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.common{
    padding: 5px;
}

*:focus{
    outline: none;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0,0,0,.65);
}

.player-dialog .mat-dialog-container {
    padding: 12px;    
    background-color: black;
    margin-top: -150px;
} 

.cdk-overlay-pane.player-dialog {
    max-width: 80vw !important;
}

@media screen and (max-width: 800px) {
    .cdk-overlay-pane.player-dialog {
        max-width: 100vw !important;
    }
}

@media screen and (min-width: 1280px) {
    .cdk-overlay-pane.player-dialog {
        max-width: 70vw !important;
    }
}

.mat-content.mat-drawer-content {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.font18{
    font-size: 18px;
}

.ytp-chrome-top-buttons{
    top: -30px;
    position: relative;
}

/* Global Font Styles */
.roboto-bold{ 
    font-family: Roboto;
    font-weight: 700;
    letter-spacing: 0;
}
.roboto-medium{ 
    font-family: Roboto;
    font-weight: 500;
    letter-spacing: 0;
}
.roboto-regular{ 
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 0;
}

/* Global Font Sizes */
.font14{
    font-size: 14px;
}
.font16{
    font-size: 16px;
}
.font18{
    font-size: 18px;
}
.font20{
    font-size: 20px;
}
.font24{
    font-size: 24px;
}
.font32{
    font-size: 32px;
}

/* Development Div Colors */

.bgRed{
    background-color: red;
}
.bgGreen{
    background-color: rgb(0, 255, 34);
}
.bgYellow{
    background-color: rgb(229, 255, 0);
}
.bgPink{
    background-color: rgb(255, 0, 191);
}
.bgOrange{
    background-color: rgb(255, 140, 0);
}
.bgBlue{
    background-color: rgb(0, 191, 255);
}
.bgPurple{
    background-color: rgb(128, 0, 128);
}

